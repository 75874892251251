import React from "react";
import {Helmet} from "react-helmet";
import loadable from "@loadable/component";
import "plyr/dist/plyr.css";
import "./header.css";

const Plyr = loadable(() => import("plyr-react"));

const Header = () => {
  return (
    <div className="headerContainer">
      
      <div className="videoContainer">
        <Plyr
          source={{
            type: "video",
            sources: [
              {
                src: "y36Ungknv_8",
                provider: "youtube",
              },
            ],
          }}
        />
      </div>
      <div className="description">
        <p>
        Our contest has concluded, but stay tuned for the Iconic Summer Mashup music video, dropping this July!
        </p>
      </div>
      <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:title" content="You could star in a video with Vanilla Ice" />
      <meta property="og:description" content="You and a friend heading to Los Angeles to join Vanilla Ice in Baby Got Buns, a sizzling new take on a 90s hip-hop classic? Now that sounds good!" />
      <meta property="og:url" content="https://bar-s.com/myiconicsummer" />
      <meta property="og:image" content="https://s3.wasabisys.com/bar-s/preview.jpg" />
    </Helmet>  
    </div>
  );
};

export default Header;
