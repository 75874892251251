import React from "react";
import Footer from "../components/footer";
import brickscontainer from "../assets/brickscontainer.jpg";
import logoheader from "../assets/logoheader.jpg";
import "../styles/global.css";
import Header from "../components/header";

const Home = () => {
  return (
    <div className="mainContainer">
      <img src={brickscontainer} alt="Bricks Container" />
      <div className="contentContainer">
        <img src={logoheader} alt="Logo" />
        <Header />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
