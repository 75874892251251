import React from "react";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import logosm from "../../assets/logo-sm.jpg";
import mustardBottle from "../../assets/mustard.png";
import "./footer.css";
import { navigate } from "gatsby-link";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="socialIcons">
        <img src={logosm} 
        alt="Logo" 
        onClick={() => navigate("https://bar-s.com")}
        />
        <img
          src={instagram}
          alt="Instagram"
          onClick={() => navigate("https://instagram.com/barsfoods")}
        />
        <img
          src={facebook}
          alt="Facebook"
          onClick={() => navigate("https://facebook.com/BarSFoods")}
        />
        <img
          src={twitter}
          alt="Twitter"
          onClick={() => navigate("https://twitter.com/barsfoods?lang=en")}
        />
      </div>
      <div className="footerImg">
        <img src={mustardBottle} alt="MustardBottle" />
      </div>
    </div>
  );
};

export default Footer;
